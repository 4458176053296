import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ALL_PATHS, LOGIN_PATH } from "Utils/constant";

export const ProtectedRoute = ({ children }) => {
  const { pathname } = useLocation();
  const authState = useSelector((state) => state.auth);
  const hasPermission =
    (authState.hasAccess?.length > 0 &&
      authState.hasAccess.includes(ALL_PATHS)) ||
    authState.hasAccess.includes(pathname);

  return (
    <>
      {authState.token ? (
        hasPermission ? (
          children
        ) : (
          "You do not have permission to view this page, please contact your administrator"
        )
      ) : (
        <Navigate to={LOGIN_PATH} replace />
      )}
    </>
  );
};
