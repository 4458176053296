import { Box, Button } from "@material-ui/core";
import { authAction } from "Store/store";
import {
  ADMIN_PATH,
  ALL_PATHS,
  COMMON_NAVBAR_ITEM,
  DESIGN_PATH,
  INVOICE_PATH,
  LOGIN_PATH,
  ORDERS_PATH,
  PRIMARY_COLOR,
  PURCHASE_PATH,
  QUOTATION_PATH,
  SALES_PATH,
} from "Utils/constant";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

const LinkButtonWrapper = ({ children, path }) => {
  const { pathname } = useLocation();
  return (
    <Link to={path} style={{ width: "90%" }}>
      <Button
        style={{
          width: "100%",
          color: pathname === path ? "black" : "white",
        }}
        size="large"
        variant={pathname === path ? "contained" : "text"}
      >
        {children}
      </Button>
    </Link>
  );
};

const NAVBAR_LIST = [
  { title: "Quotation", path: QUOTATION_PATH, id: "/quotation" },
  { title: "Invoice", path: INVOICE_PATH, id: "/invoice" },
  { title: "Admin", path: ADMIN_PATH, id: "/admin" },
  { title: "Sales", path: SALES_PATH, id: "/sales" },
  { title: "Purchase", path: PURCHASE_PATH, id: "/purchase" },
  { title: "Design", path: DESIGN_PATH, id: "/design" },
  { title: "Orders", path: ORDERS_PATH, id: "/orders" },
];

export const NavigationBar = () => {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // filter navbar lits based on permissions available
  const filteredNavbarList =
    authState.hasAccess?.length > 0
      ? authState.hasAccess.includes(ALL_PATHS)
        ? NAVBAR_LIST
        : NAVBAR_LIST.filter((item) => authState.hasAccess.includes(item.id))
      : [];

  return (
    <Box
      width="13rem"
      sx={{
        backgroundColor: PRIMARY_COLOR,
        display: "flex",
        overflowY: "auto",
      }}
      padding={"1rem 0"}
      display="flex"
      flexDirection="column"
      gridGap={"1rem"}
      alignItems="center"
      justifyContent="space-between"
      paddingTop="1rem"
      position="relative"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap={"0.5rem"}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="2rem"
          padding="1rem"
          sx={{
            bgcolor: "white",
            color: PRIMARY_COLOR,
            borderRadius: "50%",
          }}
          width="60px"
          height="60px"
        >
          {authState?.email?.slice(0, 1)?.toUpperCase()}
        </Box>
        <p
          style={{
            color: "white",
            fontSize: "1rem",
            textAlign: "center",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            marginBottom: "2rem",
            width: "90%",
          }}
        >
          {authState?.email}
        </p>
        {[COMMON_NAVBAR_ITEM, ...filteredNavbarList].map((item) => (
          <LinkButtonWrapper key={item.title} path={item.path}>
            {item.title}
          </LinkButtonWrapper>
        ))}
      </Box>
      <Button
        style={{ width: "80%", color: "#ffffff" }}
        variant="text"
        onClick={() => {
          dispatch(authAction.logout());
          localStorage.removeItem("novel-auth");
          navigate(LOGIN_PATH, { replace: true });
        }}
      >
        Logout
      </Button>
    </Box>
  );
};
