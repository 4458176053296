import React, { useEffect, useMemo, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tabs,
  Tab,
} from "@material-ui/core";
import {
  ActiveTab,
  CURRENCY_INR,
  CURRENCY_USD,
  PRIMARY_COLOR,
  documentStyles,
} from "Utils/constant";
import { v4 as uuid } from "uuid";
import { ContactSection, ListItem, PdfDocument } from "Components";
import { doc, firestore, getDoc } from "firebase.config";

const TAX_RATE = 0.18;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  createRow("Paperclips (Box)", 100, 1.15),
  createRow("Paper (Case)", 10, 45.99),
  createRow("Waste Basket", 2, 17.99),
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const INITIAL_ITEM_DETAILS = {
  id: uuid(),
  title: "",
  description: "",
  qty: 1,
  unitPrice: 0,
  total: 0,
};

export const Quotation = () => {
  const [itemList, setItemList] = useState([]);
  const [itemDetails, setItemDetails] = useState(INITIAL_ITEM_DETAILS);
  const [currency, setCurrency] = useState(CURRENCY_INR);
  const [activeTab, setActiveTab] = useState(ActiveTab().ViewAll);

  const [isLoading, setIsLoading] = useState(false);
  const [allQuotations, setAllQuotations] = useState([]);

  const fetchQuotations = async () => {
    const docRef = doc(firestore, "quotations", "quotations");
    try {
      setIsLoading(true);

      const resp = await getDoc(docRef);

      setAllQuotations(resp.data().results);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === ActiveTab().ViewAll && allQuotations.length === 0) {
      fetchQuotations();
    }
  }, [activeTab, allQuotations.length]);

  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    email: "",
    contactDetail: null,
    location: "",
    contactPerson: "",
    offerNo: null,
    date: new Date().toLocaleDateString(),
    dated: new Date().toLocaleDateString(),
    reference: "Mail",
  });

  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value);
  };

  const CurrencySelection = () => (
    <FormControl>
      <InputLabel id="currency-selection">Currency</InputLabel>
      <Select
        labelId="currency-selection"
        value={currency}
        label="Currency"
        onChange={handleChangeCurrency}
        style={{ width: "7rem" }}
      >
        <MenuItem value={CURRENCY_INR}>{CURRENCY_INR}</MenuItem>
        <MenuItem value={CURRENCY_USD}>{CURRENCY_USD}</MenuItem>
      </Select>
    </FormControl>
  );

  const MyDocument = useMemo(
    () => () =>
      (
        <PdfDocument
          currency={currency}
          customerDetails={customerDetails}
          itemList={itemList}
        />
      ),
    [currency, customerDetails, itemList]
  );

  // input details
  const handleCustomerDetail = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCustomerDetails((preDetails) => {
      return {
        ...preDetails,
        [name]: value,
      };
    });
  };

  const changeItemDetailHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setItemDetails((previousDetails) => {
      return {
        ...previousDetails,
        [name]: value,
      };
    });
  };

  const resetItemDetails = () => {
    setItemDetails(INITIAL_ITEM_DETAILS);
  };

  const addItemHandler = () => {
    if (itemDetails.title) {
      setItemList((previousList) => [
        ...previousList,
        {
          ...itemDetails,
          id: uuid(),
          total: itemDetails.qty * itemDetails.unitPrice,
        },
      ]);
      resetItemDetails();
    } else {
      alert("Enter valid details");
    }
  };

  const removeItemHandler = (id) => {
    const remainingList = itemList.filter((item) => item.id !== id);
    setItemList(remainingList);
  };

  return (
    <Box display="flex" flexDirection="column" gridGap="1rem" width="100%">
      <Tabs
        value={activeTab}
        onChange={(e, value) => setActiveTab(value)}
        centered
      >
        <Tab label="View All" />
        <Tab label="Create New" />
      </Tabs>
      <Box p="1rem" flexDirection="column" flex={1}>
        {activeTab === ActiveTab().ViewAll ? (
          <Box width="100%" display="flex" flexDirection="column">
            {allQuotations.length > 0 ? (
              allQuotations.map((item, index) => (
                <Box key={item} display="flex" gridGap={6} alignItems="center">
                  {index + 1}.{" "}
                  <Button onClick={() => window.open(item)} key={item}>
                    {item}
                  </Button>
                </Box>
              ))
            ) : (
              <p>No Quotations found</p>
            )}
          </Box>
        ) : (
          <Box
            display="flex"
            gridGap="1rem"
            flex={1}
            justifyContent="space-between"
            className="quotation-container"
          >
            <Box overflow="auto">
              <ContactSection
                customerDetails={customerDetails}
                handleCustomerDetail={handleCustomerDetail}
              />
              <Box mt={4}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <h3>Item Details</h3>
                  <CurrencySelection />
                </Box>
                {itemList.map((item) => (
                  <ListItem
                    key={item.id}
                    itemDetails={item}
                    changeItemDetailHandler={changeItemDetailHandler}
                    onDeleteClick={removeItemHandler}
                    showDelete
                  />
                ))}
                <ListItem
                  itemDetails={itemDetails}
                  changeItemDetailHandler={changeItemDetailHandler}
                />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: PRIMARY_COLOR,
                    color: "white",
                  }}
                  onClick={addItemHandler}
                >
                  Add Entry
                </Button>
              </Box>
            </Box>
            <PDFViewer style={documentStyles.viewer}>
              <MyDocument />
            </PDFViewer>
          </Box>
        )}
      </Box>
    </Box>
  );
};
