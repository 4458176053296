import { StyleSheet } from "@react-pdf/renderer";

export const ALL_PATHS = "all_paths";

export const HOME_PATH = "/";
export const QUOTATION_PATH = "/quotation";
export const INVOICE_PATH = "/invoice";
export const DESIGN_PATH = "/design";
export const ADMIN_PATH = "/admin";
export const WORKORDER_PATH = "/workorder";
export const PURCHASE_PATH = "/purchase";
export const SALES_PATH = "/sales";
export const SETTINGS_PATH = "/settings";
export const DISPATCH_PATH = "/dispatch";
export const ORDERS_PATH = "/orders";
export const LOGIN_PATH = "/login";

// sub paths
export const ACCOUNTS_PATH = `${SETTINGS_PATH}/account`;
export const SEPERATE_WORKORDER_PATH = `${WORKORDER_PATH}/:id`;
export const SEPERATE_SALE_PATH = `${SALES_PATH}/:id`;

// color
export const PRIMARY_COLOR = "#2f2483";

export const CURRENCY_INR = "INR";
export const CURRENCY_USD = "USD";

export const COMMON_NAVBAR_ITEM = {
  title: "Home",
  path: HOME_PATH,
  id: "/",
};

export const documentStyles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "800",
    color: PRIMARY_COLOR,
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 10.5,
    marginTop: 2,
    color: PRIMARY_COLOR,
    textAlign: "center",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: { border: "none", width: "100%" },
  tableHeading: {
    color: "red",
    fontWeight: "500",
    fontSize: 11,
    padding: "8px 0 0",
  },
  tableTitle: {
    minWidth: 180,
    alignSelf: "flex-start",
    justifyContent: "center",
  },
  tableSrNo: {
    alignSelf: "flex-start",
  },
  tableQty: {
    alignSelf: "flex-start",
    justifyContent: "center",
  },
  tableUnitPrice: {
    alignSelf: "flex-start",
    justifyContent: "flex-end",
  },
  tableTotal: {
    alignSelf: "flex-start",
    justifyContent: "flex-end",
  },
  tableItems: { margin: "8px 0", gap: 20, fontSize: 12 },
  viewer: {
    width: 600,
    height: 750,
  },
});

export const ActiveTab = () => ({
  CreateNew: 1,
  ViewAll: 0,
});
